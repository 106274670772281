import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import Card from "components/global/Card";
import Heading from "components/global/Heading";

const StyledTitleContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 60px 0 60px;
  flex-direction: column;
  ${breakpoint("md")`
    flex-direction: row;
  `}
  h2 {
    margin: 0 15px 0 0;
  }
  h3 {
    font-size: 26px;
    font-weight: 400;
    color: ${(props) => props.theme.colors.greyDark};
  }
`;
const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  & > div {
    margin-bottom: 60px;
  }
`;

const ProjectsList = ({ activeExpertise }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle(filter: { type: { eq: "Projet" } }) {
        nodes {
          id
          title
          slug
          tags
          heroImage {
            gatsbyImageData(width: 660, height: 450)
          }
          accroche {
            accroche
          }
          type
        }
      }
    }
  `);

  const projects = data.allContentfulArticle.nodes;
  const [projectsRender, setProjectsRender] = useState(null);

  useEffect(() => {
    setProjectsRender(
      projects.map((project) => {
        const matchProjectTag = project.tags.some(
          (tag) => tag === activeExpertise
        );
        if (matchProjectTag) {
          return <Card key={project.id} post={project} isDate={false} />;
        } else {
          return null;
        }
      })
    );
  }, [activeExpertise, projects]);

  return (
    <>
      <StyledTitleContainer>
        <Heading section>Réalisations</Heading>
        <h3>{activeExpertise}</h3>
      </StyledTitleContainer>
      <StyledCardsContainer>{projectsRender}</StyledCardsContainer>
    </>
  );
};

export default ProjectsList;
