import React, { useState } from "react";

import Seo from "components/global/Seo";
import Layout from "../components/global/Layout";
import Container from "components/global/Container";
import PageHero from "components/global/PageHero";
import ExpertisesTabs from "components/global/ExpertisesTabs";
import ProjectsList from "components/pages/projects/ProjectsList";

const Projets = () => {
  const [activeExpertise, setActiveExpertise] = useState(
    "Smart territoires – City – Building"
  );

  const handleActiveExpertise = (activeExpertise) => {
    setActiveExpertise(activeExpertise);
  };

  return (
    <>
      <Seo pageTitle="Nos projets" />
      <Layout>
        <Container>
          <PageHero
            pageTitle="Nos projets"
            pageDecription="LM Ingénierie intervient et accompagne depuis 2006 ses clients Publics et privées dans tout leur projet lié aux Territoires & Bâtiments connectés. La TEAM LMI vous accompagne à toutes les étapes de votre projet. Nos compétences et expériences nous permettent d’intervenir de la genèse d’un projet, pour l’audit, l’analyse des besoins et la feuille de route à suivre, l’aide au choix des outils/solutions ou entreprises à sélectionner jusqu’à la réception et garantie de bon achèvement de votre projet."
            imgName="projets"
          />
          <ExpertisesTabs
            activeExpertise={activeExpertise}
            activeExpertiseCallBack={handleActiveExpertise}
          />
          <ProjectsList activeExpertise={activeExpertise} />
        </Container>
      </Layout>
    </>
  );
};

export default Projets;
